// Colours
$main-bg-color: #F0F0F0;
$sidebar-bg-color: rgb(1,73,97);
$sidebar-color: rgba(255, 255, 255,.87);
$menubar-color: rgba(0, 0, 0,.70);
$accent-color: rgb(0, 150, 136);
$warning-color: #E75753;

$color-1: #008C93;
$color-2: #8A86EA;
$color-3: #FF8200;
$color-4: #EA71B6;
$color-5: #9AB800;
$color-6: #00C0E0;

// Variables
$left-sidenav-width: 110px;
$widget-height: 310px;
$font-weight-bold: 600;
$font-size-large: 22px;

body {
  background-color: $main-bg-color;
}

/* Side Nav */
.sidebar {
	background-color: $sidebar-bg-color;
}
md-sidenav {
	color: $sidebar-color;
	background-color: $sidebar-bg-color;
	max-width: 240px;
	padding-top: 20px;
	.md-button {
		width: 100%;
		text-align: left;
	}
	md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
		min-height: auto;
	}
	i {
		margin-right: 10px;
	}
	img.menu-logo {
		margin-bottom: 40px;
	}
}

/* Content */
md-content {
	background-color: $main-bg-color;

	/* Main Toolbar */
	md-toolbar.pageheader:not(.md-menu-toolbar) {
		background-color: #FFF;
		color: $menubar-color;
		height: 20px;
		margin-bottom: 1px;
	}
	.info-bar {
		background-color: #FFF;
		color: $menubar-color;
		h4 {
			font-size: 1.3em;
			margin: 0;
			font-weight: 200;
		}
	}

	/* Buttons */
	.md-button {
		&.refresh {
			margin-left: 20px;
		}
	}

	/* Content Rows */
	.row-t, .row-m {
		margin-bottom: 0;
	}
	.row-m, .row-b {
		margin-top: 0;
	}
	.info-rows {
		> div {
			background-color: #FFF;
		}
		.huge {
			font-size: 200%;
		}
	}
	.row-b {
		.material-icons {
			margin-right: 10px;
		}
	}
}

/* Custom Error for Icons */
md-input-container.md-input-invalid > md-icon {
	color: red;
}

/* Custom alignment for non-form elements and datepicker */
.align-non-form {
	margin-top: 15px;
	&.right {
		margin-right: 0;
		text-align: right;
	}
}

/* Login Form */
.login-form, .landing-form {
	padding-bottom: 30px;
	md-whiteframe {
		background: #FFF;
		.LoginForm-inputContainer {
			margin: 18px 20px 10px;
		}
	}
	.margin-top-30 {
		margin-top: 30px;
	}
}

/* Signup Form */
.signup-form {
	span.md-headline {
		font-size: 20px;
		color: $sidebar-bg-color;
	}
}

/* Dashboard */
md-toolbar.pallete-bespoke-teal {
	background-color: $sidebar-bg-color;
}
.row-m {
	md-card md-card-header {
		md-card-avatar {
			width: 70px;
			width: 70px;
		}
		.md-title {
			font-size: 44px;
		}
	}
}

/* Doughnut Chart */
#doughtnut {
	padding-top: 20px;
	max-height: 412px;
    max-width: 824px
}
.dashboard-chart {
	height: 500px;
}
/* Newsletter list */
.newsletter {
	height: 500px;
	md-content {
		background-color: #fff;
		img.md-avatar {
			border-radius: 0;
		}
	}
}

/* Admin Dashboard */
.admin-dashboard {
	.material-icons {
		margin-right: 10px;
	}
	.admin-search {
		margin-left: 15px;
		margin-top: 0;
	}
}
.material-icons.huge-icon {
	font-size: 240px;
}