// This file based on http://codepen.io/zavoloklom/pen/IGkDz

// VARIABLES
$table-header-font-weight:      400;
$table-header-font-color:       #757575;

$table-cell-padding:            1.2rem;

$table-bg:                      #fff;
$table-bg-accent:               #f5f5f5;
$table-bg-hover:                rgba(0,0,0,.12);
$table-bg-active:               $table-bg-hover;
$table-border-color:            #e0e0e0;


// MIXINS
@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}


//TABLES
.table {
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: $table-bg;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      @include transition(all, 0.3s, ease);
      > th,
      > td {
        text-align: left;
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: 0;
        @include transition(all, 0.3s, ease);
      }
    }
  }
  > thead > tr > th {
    font-weight: $table-header-font-weight;
    color: $table-header-font-color;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  > tbody + tbody {
    border-top: 1px solid rgba(0,0,0,.12);
  }

  // Nesting
  .table {
    background-color: $table-bg;
  }

  // Remove border
  .no-border {
    border: 0;
  }
}

// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0;
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Hover effect
//
.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: $table-bg-hover;
    }
  }
}

// Responsive tables (vertical)
//
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.table-responsive-vertical {

  @media screen and (max-width: 768px) {

    // Tighten up spacing
    > .table {
      margin-bottom: 0;
      background-color: transparent;
      > thead,
      > tfoot {
        display: none;
      }

      > tbody {
        display: block;

        > tr {
          display: block;
          border: 1px solid $table-border-color;
          border-radius: 2px;
          margin-bottom: $table-cell-padding;

          > td {
            background-color: $table-bg;
            display: block;
            vertical-align: middle;
            text-align: right;
          }
          > td[data-title]:before {
            content: attr(data-title);
            float: left;
            font-size: inherit;
            font-weight: $table-header-font-weight;
            color: $table-header-font-color;
            padding-right: 10px;
          }
        }
      }
    }

    // Special overrides for shadows
    &.shadow-z-1 {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      > .table > tbody > tr {
        border: none;
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > tbody {
        > tr {
          > td {
            border: 0;
            border-bottom: 1px solid $table-border-color;
          }
          > td:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    // Special overrides for the striped tables
    > .table-striped {
      > tbody > tr > td,
      > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
      }
      > tbody > tr > td:nth-child(odd) {
        background-color: $table-bg-accent;
      }
    }

    // Special overrides for hover tables
    > .table-hover {
      > tbody {
        > tr:hover > td,
        > tr:hover {
          background-color: $table-bg;
        }
        > tr > td:hover {
          background-color: $table-bg-hover;
        }
      }
    }
  }
}
